import React from 'react'
import ReactDom from 'react-dom'
import './style/admin/index.less'
import './style/lib/animate.css';
import './style/global.css';
import './index.scss';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux'
import  {  persistStore ,  persistReducer  }  from  'redux-persist' 
import { PersistGate } from 'redux-persist/integration/react'
import  storage  from  'redux-persist/lib/storage'// 默认为localStorage for web 
import { createStore, applyMiddleware, compose } from 'redux'
import RouteMap from './routes'
import reducers from './reducers'
import thunk from 'redux-thunk'
// import './axiosToast'
import { BrowserRouter, Route, Redirect,Switch } from 'react-router-dom'

// import configureStore from './store/configureStore'
//可添加黑白名单blacklist，whitelist数组，设置哪些reducer是否被持久化，例whitelist : [ 'auth','user' ]
const  persistConfig  =  { 
  key : 'root' , 
  storage , 
}
const persistedReducer = persistReducer(persistConfig, reducers)
// 创建 Redux 的 store 对象
// const store = createStore(reducers, compose(
// 	applyMiddleware(thunk),
// 	window.devToolsExtension?window.devToolsExtension():f=>f
// ))
const store = createStore(persistedReducer, compose(
	applyMiddleware(thunk),
	window.devToolsExtension?window.devToolsExtension():f=>f
))

let persistor = persistStore(store)

ReactDom.render(
  (<Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <RouteMap/>
    </PersistGate>
   </Provider>
   ),document.getElementById('app')
)
